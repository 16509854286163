<template>
  <div>
    <form
      v-shortkey="shortcutKeys"
      @shortkey="toggleShortkey"
      @submit.prevent="save"
      v-if="isExpanded"
      class="container bg-white p-2"
      id="specimen-form"
    >
      <GCodePopup />
      <form @submit.prevent="addSpecimen" class="d-flex mb-2 justify-content-between">
        <div class="d-flex justify-content-start align-items-end">
          <select-input
            v-if="usesHoppers"
            :inputAttr="{ required: true }"
            name="hopper"
            :items="hoppers"
            v-model="hopperId"
            label="Hopper"
            :validationRules="[{ type: 'required' }]"
            :validator="$v.hopperId"
            id="hopper"
            ref="hopper"
            accessKey="h"
          />
          <SelectInput
            :inputAttr="{ required: true }"
            name="protocolId"
            :items="protocolOptions"
            v-model="protocolId"
            label="Protocol"
            accessKey="o"
            :validator="$v.protocolId"
            id="protocol"
            ref="protocol"
            searchMode="startswith"
            :disabled="hasProstateSpecimen"
          />
          <button
            v-if="labSettings.SpecimenProtocolPopup"
            class="btn btn-primary input-btn ml-2"
            @click.prevent="isProtocolPopupOpen = true"
          >
            Inp<u>u</u>t Protocol
          </button>
        </div>
        <icon-button
          v-if="permissions.CaseSpecimenCreateEdit"
          type="submit"
          data-testid="addSpecimen"
          icon="flask"
          class="d-flex justify-content-center align-self-end align-items-end btn btn-outline-primary"
          v-tooltip="'Add specimen<br>Alt + A'"
        >
          <icon class="align-self-start" icon="plus" />
          <span>Add</span>
        </icon-button>
      </form>
      <DxDataGrid
        :columns="columns"
        :dataSource="dataSource"
        :editing="editing"
        @cell-click="changeSelectedRow"
        :selection="selection"
        @selection-changed="onSelectionChanged"
        :showRowLines="true"
        @content-ready="onContentReady"
        :onRowRemoved="handleSpecimenRemove"
        @focused-cell-changed="handleFocusedCellChanged"
        :cacheEnabled="false"
      />
      <div v-if="specimen" class="form-row header d-flex align-items-center my-2">
        <h4>
          Specimen ID: *
          <span class="text-uppercase">
            {{ specimen.specimenOrder }}
          </span>
          <span>{{ site }}</span>
        </h4>
      </div>
      <div class="container">
        <macro-enabled-editor
          :class="{ 'text-uppercase': upperCaseClinical }"
          v-if="specimen"
          name="clinical"
          label="Clinical"
          ref="clinical"
          accessKey="c"
          id="clinical"
          v-model="specimen.clinical"
          @editorReady="handleAutoOpenEditor"
        />
        <macro-enabled-editor
          :class="{ 'text-uppercase': upperCaseGross }"
          v-if="specimen"
          name="gross"
          label="Gross"
          ref="gross"
          accessKey="g"
          id="gross"
          v-model="specimen.gross"
          @editorReady="handleAutoOpenEditor"
        />
        <macro-enabled-editor
          :class="{ 'text-uppercase': upperCaseCaseNote }"
          v-if="specimen"
          name="caseNotes"
          label="Case Notes"
          ref="caseNotes"
          accessKey="t"
          id="caseNotes"
          v-model="caseNotes"
        />
        <macro-enabled-editor
          :class="{ 'text-uppercase': upperCaseSpecimenNote }"
          v-if="specimen"
          name="specimenNotes"
          label="Specimen Notes"
          ref="specimenNotes"
          accessKey="t"
          id="specimenNotes"
          v-model="specimen.notes"
        />
      </div>
      <div class="d-flex justify-content-end my-2">
        <button
          accesskey="f"
          data-testid="finalizeSpecimen"
          v-if="caseCompletionMode === 2 && permissions.CaseFinalizeSlidePrep"
          :disabled="!allSpecimensGrossed"
          @click="finalizeSpecimen"
          type="button"
          class="btn btn-success mr-1"
        >
          Finalize
        </button>
        <button
          data-testid="saveSpecimens"
          :disabled="!permissions.CaseSpecimenCreateEdit"
          type="submit"
          class="btn btn-primary"
        >
          Save
        </button>
      </div>
    </form>
    <modal :status="isProtocolPopupOpen" @close="isProtocolPopupOpen = false">
      <SpecimenProtocolPopup
        :specimens="value"
        :protocolOptions="protocolOptions"
        @submit="handleSubmitProtocols"
        @cancel="isProtocolPopupOpen = false"
      />
    </modal>
  </div>
</template>

<script>
import MacroEnabledEditor from "@/components/common/MacroEnabledEditor.vue";
import { mapState, mapGetters } from "vuex";
import {
  fromLetters,
  getAltKeys,
  getNextSpecimenOrder,
  getProstateSites,
  getTextFromHtml,
  scrollToElement,
  sortCaseInsensitive,
  toLetters
} from "@/modules/helpers";
import { DxDataGrid } from "devextreme-vue";
import { helpers } from "vuelidate/lib/validators";
import { DropdownApi, MacrosApi } from "../../../services";
import { delay, map, tap, filter, switchMap } from "rxjs/operators";
import DataSource from "devextreme/data/data_source";
import SelectInput from "@/components/common/SelectInput.vue";
import IconButton from "@/components/common/IconButton.vue";
import Icon from "@/components/common/Icon.vue";
import ArrayStore from "devextreme/data/array_store";
import GCodePopup from "@/components/GCodePopup";
import { SpecimenNumbersEnum } from "@/modules/enums";
import Modal from "@/components/common/Modal.vue";
import SpecimenProtocolPopup from "@/components/SpecimenProtocolPopup";

export default {
  components: {
    MacroEnabledEditor,
    DxDataGrid,
    SelectInput,
    IconButton,
    Icon,
    GCodePopup,
    Modal,
    SpecimenProtocolPopup
  },
  props: [
    "isExpanded",
    "value",
    "caseDetails",
    "currentPrefix",
    "specimenNumberingOption",
    "prefixProtocols",
    "historyMatchingStatus"
  ],
  data() {
    return {
      selected: 0,
      specimen: null,
      selectedSpecimenBlocks: null,
      protocolId: null,
      hopperId: null,
      defaultSpecimen: {
        protocolId: null,
        gross: "",
        clinical: "",
        caseNotes: "",
        notes: ""
      },
      shortcutKeys: getAltKeys("abistu"),
      protocolOptions: [],
      protocolOrders: [],
      editing: {
        allowUpdating: true,
        allowDeleting: true,
        confirmDelete: true,
        mode: "cell",
        useIcons: true,
        startEditAction: "click"
      },
      specimenGrid: {},
      hoppers: [],
      selection: { mode: "single" },
      macroSource: new DataSource({
        store: MacrosApi.searchStore,
        select: ["macroId", "procedures"]
      }),
      hasContentReadyHappened: false,
      caseNotes: "",
      isProtocolPopupOpen: false,
      hasLoadedSpecimens: false
    };
  },
  mounted() {
    if (this.labSettings.UsesHoppers) {
      DropdownApi.getHopper(this.currentLab).then(res => {
        this.hoppers = res || [];
      });
    }
    if (this.prefixProtocols?.length) {
      this.protocolOptions = sortCaseInsensitive(this.prefixProtocols);
      this.$nextTick(() => {
        this.labSettings.AutoAddSpecimens && this.loadNumberOfSpecimens();
      });
    }
  },
  watch: {
    specimen: {
      immediate: true,
      handler(nv) {
        this.$store.commit("accessionStore/setCurrentSpecimen", nv);
      }
    },
    specimens: {
      immediate: true,
      handler(nv, ov) {
        if (this.hasProstateSpecimen && nv.length !== ov.length) {
          nv = getProstateSites(nv, this.prostateProtocolId);
        }
        this.$store.commit("accessionStore/setCaseSpecimens", nv);
      }
    },
    "specimen.specimenOrder": {
      immediate: true,
      handler(nv) {
        if (typeof nv !== "string") {
          return;
        }
        this.specimen.specimenOrder = nv.toUpperCase();
      }
    },
    targetPrefix: {
      handler(nv) {
        if (nv) {
          DropdownApi.getProtocol(nv).then(res => {
            this.protocolOptions = res || [];
            if (this.value?.length) {
              this.specimens = this.loadSpecimenSlides(this.specimens);
            }
          });
        }
      }
    },
    specimenNumberingOption: {
      handler(nv) {
        if (nv && this.specimens.length) {
          for (const specimen of this.specimens) {
            if (nv === SpecimenNumbersEnum.Numbers && typeof specimen.specimenOrder === "string") {
              specimen.specimenOrder = fromLetters(specimen.specimenOrder);
            }
            if (nv === SpecimenNumbersEnum.Letters && typeof specimen.specimenOrder === "number") {
              specimen.specimenOrder = toLetters(specimen.specimenOrder);
            }
          }
        }
      }
    },
    hasProstateSpecimen: {
      immediate: true,
      handler(nv) {
        if (nv) {
          this.handleProstateProtocol();
        }
      }
    }
  },
  validations() {
    return {
      protocolId: {
        required: value => (this.labPrefix?.isProtocolRequired ? helpers.req(value) : true)
      },
      hopperId: {
        required: value => (this.usesHoppers ? helpers.req(value) : true)
      },
      specimens: {
        $each: {
          gross: {
            required: value => (this.currentPrefix?.grossIsRequired ? helpers.req(value) : true)
          }
        }
      }
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      labSettings: state => state.labSettings,
      currentLab: state => state.currentLab,
      sessionDetails: state => state.sessionsDetails,
      autoOpenEditors: state => state.applicationSettings.autoOpenEditors
    }),
    ...mapGetters(["permissions"]),
    ...mapGetters("accessionStore", ["specimenNumbering"]),
    caseCompletionMode() {
      if (this.currentPrefix) {
        return this.currentPrefix.caseCompletionModeId;
      }
      return null;
    },
    targetPrefix() {
      if (this.currentPrefix?.id) {
        return this.currentPrefix.id;
      }
      return null;
    },
    site() {
      return this.specimen?.site || "";
    },
    columns() {
      return [
        {
          dataField: "specimenOrder",
          dataType: "string",
          caption: "Specimen",
          allowEditing: true
        },
        {
          dataField: "hopperId",
          visible: this.usesHoppers,
          lookup: {
            allowClearing: false,
            dataSource: this.hoppers || [],
            displayExpr: "displayName",
            valueExpr: "id"
          },
          validationRules: this.usesHoppers && [{ type: "required" }]
        },
        {
          dataField: "protocolId",
          caption: "Protocol",
          allowEditing: !this.hasProstateSpecimen,
          editorOptions: {
            showClearButton: true,
            searchMode: "startswith"
          },
          lookup: {
            allowClearing: false,
            dataSource: this.protocolOptions || [],
            displayExpr: "displayName",
            valueExpr: "id"
          },
          setCellValue: (newData, value) => {
            const { specimenOrder } = this.specimen;
            if (value == null) {
              newData.slides = 0;
              newData.numberOfBlocks = 0;
            }
            newData.protocolId = value;
            this.handleProtocolGross(value, specimenOrder);
          },
          validationRules: this.labPrefix?.isProtocolRequired && [{ type: "required" }]
        },
        {
          dataField: "site",
          dataType: "text",
          allowEditing: true,
          showEditorAlways: true,
          validationRules: this.labPrefix?.siteRequired && [{ type: "required" }]
        },
        {
          dataField: "numberOfBlocks",
          caption: "Blocks",
          dataType: "number",
          allowEditing: true,
          showEditorAlways: true,
          setCellValue: async (newData, newBlockNumber, currentRow) => {
            if (!currentRow.protocolId) {
              window.notify("Please select a protocol first.", "warning");
              return;
            }
            const targetProtocol = this.protocolOptions.find(e => e.id === currentRow.protocolId);
            if (!targetProtocol) {
              return;
            }
            if (targetProtocol.blocks > newBlockNumber) {
              const confirm = await window.confirm(
                "Decreasing the number of blocks will remove associated orders. <br/>Your slide count may be off in the grid. <br/> Are you sure?"
              );
              if (!confirm) {
                newData.numberOfBlocks = targetProtocol.blocks;
              } else {
                newData.numberOfBlocks = newBlockNumber;
              }
            } else {
              newData.numberOfBlocks = newBlockNumber;
            }

            if (newBlockNumber === 0) {
              newData.slides = 0;
            }

            if (newBlockNumber === 1) {
              newData.slides = targetProtocol.firstBlockSlides;
            } else {
              const difference = newBlockNumber - targetProtocol.blocks;
              if (difference < 0) {
                newData.slides = newBlockNumber * targetProtocol.firstBlockSlides;
              } else {
                newData.slides =
                  difference * targetProtocol.firstBlockSlides + targetProtocol.slides;
              }
            }
          }
        },
        {
          dataField: "slides",
          dataType: "number",
          allowEditing: false
        }
      ];
    },
    specimens: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      }
    },
    dataSource() {
      return new ArrayStore({
        data: this.createSpecimenIndex(this.specimens),
        key: "specimenIndex",
        onUpdated: async (key, values) => {
          const { protocolId } = values;
          if (protocolId) {
            if (this.labSettings.GetSiteFromProtocolDescription) {
              const protocol = this.protocolOptions.find(e => e.id === protocolId);
              if (protocol?.protocolDescription) {
                this.value[key].site = protocol.protocolDescription;
              }
            }
            return this.handleRowUpdate();
          }
          this.$store.commit(
            "accessionStore/setCaseSpecimens",
            this.specimens.map((e, idx) => {
              if (idx + 1 === key) {
                return { ...e, ...values };
              }
              return e;
            })
          );
        }
      });
    },
    //If the labsettings indicate it we will display the text to the user as uppercase.
    //This allows better UX if we were to update the value as the user types it will move the cursor to the front of the text box.
    upperCaseGross() {
      const { ForceUpperCaseGross } = this.labSettings;
      if (ForceUpperCaseGross) {
        return true;
      }
      return false;
    },
    upperCaseClinical() {
      const { ForceUpperCaseClinical } = this.labSettings;
      if (ForceUpperCaseClinical) {
        return true;
      }
      return false;
    },
    upperCaseCaseNote() {
      const { ForceUpperCaseCaseNote } = this.labSettings;
      if (ForceUpperCaseCaseNote) {
        return true;
      }
      return false;
    },
    upperCaseSpecimenNote() {
      const { ForceUpperCaseSpecimenNote } = this.labSettings;
      if (ForceUpperCaseSpecimenNote) {
        return true;
      }
      return false;
    },
    usesHoppers() {
      const { UsesHoppers } = this.labSettings;
      if (UsesHoppers) {
        return true;
      }
      return false;
    },
    allSpecimensGrossed() {
      if (this.prefix?.grossIsRequired === false) {
        return true;
      }
      const allSpecimensWithGross = this.specimens.filter(
        specimen => specimen?.gross && getTextFromHtml(specimen.gross)
      );
      return allSpecimensWithGross.length === this.specimens.length;
    },
    hasProstateSpecimen() {
      for (const specimen of this.specimens) {
        if (specimen.protocolId) {
          const protocol = this.protocolOptions.find(e => e.id === specimen.protocolId);
          if (protocol?.isProstate) {
            return true;
          }
        }
      }
      return false;
    },
    prostateProtocolId() {
      for (const specimen of this.specimens) {
        if (specimen.protocolId) {
          const protocol = this.protocolOptions.find(e => e.protocolId === specimen.protocolId);
          if (protocol?.isProstate) {
            return protocol.id;
          }
        }
      }
      return null;
    }
  },
  domStreams: ["selectionChange$"],
  subscriptions() {
    const historyHasMatched$ = this.$watchAsObservable("historyMatchingStatus", {
      immediate: true
    }).pipe(filter(({ newValue }) => newValue !== "none"));
    const hasLoadedSpecimens$ = this.$watchAsObservable("hasLoadedSpecimens", {
      immediate: true
    }).pipe(filter(({ newValue }) => newValue));
    const openProtocolPopup$ = historyHasMatched$.pipe(
      switchMap(() => hasLoadedSpecimens$),
      tap(() => {
        this.isProtocolPopupOpen = true;
      })
    );
    return {
      selectedRows$: this.selectionChange$.pipe(
        map(({ event }) => {
          const { selectedRowKeys, selectedRowsData, component } = event.msg;
          this.specimen = selectedRowsData[0];
          return component.getRowIndexByKey(selectedRowKeys[0]);
        }),
        delay(50)
      ),
      openProtocolPopup$
    };
  },
  methods: {
    toggleShortkey(event) {
      switch (event.srcKey) {
        case "a":
          this.addSpecimen();
          break;
        case "b":
          this.editCell("numberOfBlocks");
          break;
        case "i":
          this.editCell("site");
          break;
        case "s":
          this.save();
          break;
        case "t":
          if (this?.$refs?.caseNotes?.$refs?.editor?.isFocused) {
            this.$refs.specimenNotes?.focus();
          } else {
            this.$refs?.caseNotes?.focus();
          }
          break;
        case "u":
          this.isProtocolPopupOpen = true;
          break;
        default:
          break;
      }
    },
    handleSpecimenRemove({ data }) {
      this.specimens = this.specimens.filter(e => e.specimenOrder !== data.specimenOrder);
    },
    async loadNumberOfSpecimens() {
      const targetProtocol = this.protocolOptions.find(
        e => e.id === this.currentPrefix?.protocolId
      );
      let gross = null;
      if (targetProtocol) {
        this.protocolId = this.currentPrefix.protocolId;
        const protocolDetails = await MacrosApi.getMacroDetails(this.currentPrefix.protocolId);
        if (protocolDetails?.gross) {
          gross = protocolDetails.gross;
        }
      }
      if (this.specimens?.length === 0 && Number(this.caseDetails?.numberOfSpecimens) > 0) {
        const specimens = [];
        for (let i = 0; i < Number(this.caseDetails.numberOfSpecimens); i++) {
          const newSpecimen = {
            caseId: this.caseDetails.caseId,
            protocolId: this.protocolId,
            hopperId: null,
            gross: gross || "",
            clinical: "",
            numberOfBlocks: 1,
            labId: this.currentLab,
            slides: 0,
            cassettes: [],
            newSpecimen: true,
            specimenOrder: getNextSpecimenOrder(specimens, this.specimenNumberingOption || 0),
            site: this.currentPrefix?.site || "",
            caseNotes: this.caseNotes,
            notes: ""
          };
          specimens.push(newSpecimen);
        }
        const viewSpecimensList = this.createSpecimenIndex(specimens);
        this.specimens = this.loadSpecimenSlides(viewSpecimensList);
      }
      if (this.labSettings.SpecimenProtocolPopup) {
        this.focusProtocol();
      }
    },
    finalizeSpecimen() {
      if (this.allSpecimensGrossed) {
        this.$emit("finalize");
      }
    },
    handleRowUpdate() {
      this.specimens = this.loadSpecimenSlides(this.specimens);
    },
    loadSpecimenSlides(specimens) {
      return specimens.map(specimen => {
        const { protocolId } = specimen;
        const targetProtocol = this.protocolOptions.find(protocol => protocol.id === protocolId);
        if (targetProtocol) {
          specimen.slides = targetProtocol.slides;
          specimen.numberOfBlocks = targetProtocol.blocks;
        }
        return specimen;
      });
    },
    focusCell(dataField) {
      if (!this.specimens.length) {
        return;
      }
      const selectedRows = this.specimenGrid.getSelectedRowKeys() || [];
      if (!selectedRows.length) {
        this.specimenGrid.selectRowsByIndexes([0]);
        return this.$nextTick(() => {
          this.focusCell(dataField);
        });
      }
      selectedRows.forEach(key => {
        const rowIndex = this.specimenGrid.getRowIndexByKey(key);
        const element = this.specimenGrid.getCellElement(rowIndex, dataField);
        if (element) {
          const input = element.querySelector(".dx-texteditor-input-container > input");
          if (input) {
            return input.focus();
          }
        }
      });
    },
    editCell(cellName) {
      const selectedRowIndex = this.specimen.specimenIndex;
      this.specimenGrid.editCell(selectedRowIndex, cellName);
    },
    onContentReady({ element, component }) {
      this.specimenGrid = component;
      const siteCaption = element.querySelector(
        `td[aria-label="Column Site"] > div.dx-datagrid-text-content`
      );
      const blocksCaption = element.querySelector(
        `td[aria-label="Column Blocks"] > div.dx-datagrid-text-content`
      );
      if (siteCaption) {
        siteCaption.innerHTML = `S<u>i</u>te`;
      }
      if (blocksCaption) {
        blocksCaption.innerHTML = `<u>B</u>locks`;
      }
      const currentSelectedRows = component.getSelectedRowKeys();
      if (!currentSelectedRows?.length) {
        component.selectRowsByIndexes([0]);
        if (this.value[0]?.clinical) {
          this.$nextTick(() => {
            this.$refs.clinical.isExpanded = true;
          });
        }
      }
      this.$nextTick(() => {
        if (this.labSettings.SkipDemographicsatAccessioning && !this.hasContentReadyHappened) {
          this.focusProtocol();
          this.hasContentReadyHappened = true;
        }
      });
    },
    save() {
      const {
        labSettings: {
          ForceUpperCaseGross,
          ForceUpperCaseClinical,
          ForceUpperCaseCaseNote,
          ForceUpperCaseSpecimenNote
        }
      } = this.$store.state;
      let allHaveSite = true;
      //If the lab settings specify, we will uppercase all of the HTML output from the wysiwyg and then emit our save event.
      this.specimens.forEach(entry => {
        entry.caseNotes = this.caseNotes;
        if (ForceUpperCaseClinical) {
          entry.clinical = entry.clinical.toUpperCase();
        }
        if (ForceUpperCaseGross) {
          entry.gross = entry.gross.toUpperCase();
        }
        if (ForceUpperCaseCaseNote) {
          entry.caseNotes = entry.caseNotes.toUpperCase();
        }
        if (ForceUpperCaseSpecimenNote && entry?.notes) {
          entry.notes = entry.notes.toUpperCase();
        }
      });
      if (!allHaveSite) {
        return window.notify("All specimens must have a site.", "error");
      }
      this.$emit("save");
    },
    addSpecimen() {
      const caseId = this.caseDetails?.caseId;
      const { protocolId, hopperId } = this;
      if (this.$v) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        let site = "";
        if (this.labSettings.GetSiteFromProtocolDescription && protocolId) {
          const targetProtocol = this.protocolOptions.find(e => e.id === protocolId);
          if (targetProtocol && targetProtocol?.protocolDescription) {
            site = targetProtocol.protocolDescription;
          }
        }
        if (!site && this.currentPrefix?.site) {
          site = this.currentPrefix.site;
        }
        const newSpecimen = {
          caseId,
          protocolId,
          hopperId,
          gross: "",
          clinical: "",
          caseNotes: "",
          notes: "",
          numberOfBlocks: 1,
          labId: this.currentLab,
          slides: 0,
          cassettes: [],
          newSpecimen: true,
          specimenOrder: getNextSpecimenOrder(this.specimens, this.specimenNumberingOption || 0),
          site
        };
        const viewSpecimensList = this.createSpecimenIndex([...this.specimens, newSpecimen]);

        this.specimens = this.loadSpecimenSlides(viewSpecimensList);
        const targetProtocol = this.protocolOptions.find(
          e => e.id === this.currentPrefix?.protocolId
        );
        if (targetProtocol) {
          this.protocolId = this.currentPrefix.protocolId;
        } else {
          this.protocolId = null;
        }
        this.hopperId = null;
        this.$nextTick(() => {
          if (this.usesHoppers) {
            const { hopper } = this.$refs;
            if (hopper) {
              hopper.focus();
            }
          } else {
            const { protocol } = this.$refs;
            if (protocol) {
              protocol.focus();
            }
          }
          this.specimen = this.specimens.find(e => e.specimenOrder === newSpecimen.specimenOrder);
        });
      }
    },
    displayName(id) {
      return this.protocols.find(e => {
        return e.id === id;
      }).displayName;
    },
    onSelectionChanged({ selectedRowKeys, selectedRowsData, component }) {
      this.specimen = selectedRowsData[0];
      this.selected = component.getRowIndexByKey(selectedRowKeys[0]);
    },
    createSpecimenIndex(specimens) {
      const viewSpecimensList = specimens.map((e, index) => {
        e.specimenIndex = index;
        return e;
      });
      return viewSpecimensList;
    },
    changeSelectedRow({ key, data, component }) {
      this.specimen = data;
      this.selected = component.getRowIndexByKey(key);
      component.selectRowsByIndexes(key);
    },
    handleProtocolGross(protocolId, specimenOrder) {
      if (protocolId) {
        const index = this.specimens.map(e => e.specimenOrder).indexOf(specimenOrder);
        MacrosApi.getMacroDetails(protocolId).then(protocol => {
          if (protocol.gross) {
            if (specimenOrder === this.specimens[index].specimenOrder) {
              this.specimen.gross = protocol.gross;
            } else {
              this.specimens[index].gross = protocol.gross;
            }
          }
        });
      }
    },
    handleAutoOpenEditor(editorName) {
      if (this.autoOpenEditors && this.$refs[editorName]?.expand) {
        this.$refs[editorName].expand();
      }
    },
    focusProtocol() {
      setTimeout(() => {
        if (this.value.length) {
          if (this.labSettings.SpecimenProtocolPopup && this.isExpanded) {
            this.hasLoadedSpecimens = true;
          } else {
            this.focusRowCell(0, "protocolId");
          }
        } else {
          this.$refs.protocol.focus();
        }
      }, 500);
    },
    focusRowCell(rowIndex, dataField) {
      this.specimenGrid.editCell(rowIndex, dataField);
    },
    handleFocusedCellChanged(data) {
      const dataField = data.column.name;
      if (data.rowIndex !== this.selected) {
        this.specimen = this.value[data.rowIndex];
        this.selected = this.specimenGrid.getRowIndexByKey(data.rowIndex);
        this.specimenGrid.selectRowsByIndexes(data.rowIndex);
      }
      if (["protocolId", "site"].includes(dataField) && !this.value[data.rowIndex][dataField]) {
        this.specimenGrid.editCell(data.rowIndex, dataField);
      }
    },
    handleSubmitProtocols(data) {
      this.specimens = data;
      this.specimen = data[0];
      this.isProtocolPopupOpen = false;
      const el = document.getElementById("specimen-form");
      scrollToElement(el);
    },
    handleProstateProtocol() {
      const prostateProtocols = this.protocolOptions.filter(e => e?.isProstate).map(e => e.id);
      const { protocolId } = this.specimens.find(e => prostateProtocols.includes(e.protocolId));
      if (protocolId) {
        this.specimens = getProstateSites(this.specimens, protocolId);
        this.protocolId = protocolId;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
tr {
  cursor: pointer;
}
.currentSpecimen {
  background-color: #e6f5fc;
}
td {
  input,
  select {
    border: none;
    background: none;
    width: 100%;
  }
}
::v-deep .dx-datagrid .dx-link[title="Delete"] {
  color: $red;
}
.input-btn {
  height: 2.5rem;
}
</style>
